(function () {

  $(".menu__section--suggestion").addClass("open");

  $(".menu__section").click(function(e){
    if ($(this).hasClass("open")){
        $(this).removeClass("open");
    }else{
      $(this).addClass("open");
    }
  });

})();
