(function($) {
    $('.header__slider').slick({
        arrows: true,
        dots: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        pauseOnHover: false,
        nextArrow: '.next-slide',
        prevArrow: '.prev-slide'
    });

    $('.gallery__slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        dots: false,
        infinite: true,
        centerMode: true,
        arrows: false,
        centerPadding: '20px',
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerPadding: '0px',
                    centerMode: false,
                    arrows: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    centerPadding: '0px',
                    centerMode: false,
                    nextArrow: ".nextslide",
                    prevArrow: ".prevslide",
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: true,
                }
            },
        ]
    });

    $('.article__gallery').slick({
        infinite: true,
        dots: false,
        arrows: true,
        fade: true,
    });

    $('.godown').on('click', function(e) {
        e.preventDefault();
        var myval = $(".section--main").offset().top;
        $("html, body").animate({
            scrollTop: myval + "px"
        });
        return false;
    });

})(jQuery);
